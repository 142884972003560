import React, { useEffect } from 'react';
import { useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useChoreographerAssets } from '@confluence/choreographer-services';

import { EventSourceName } from './utils/analytics';
import { commonI18n, verifiedContentI18n } from './i18n';
import AutomationDiscoveryHeader from './assets/AutomationDiscoveryHeader.svg';

interface VerifiedContentSpotlightProps {
	handleDismiss: () => void;
	handleProceed: () => void;
}
export const VerifiedContentSpotlight = ({
	handleDismiss,
	handleProceed,
}: VerifiedContentSpotlightProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { Spotlight } = useChoreographerAssets();

	// Fire analytics event when spotlight is viewed
	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				action: 'viewed',
				name: EventSourceName.VERIFED_CONTENT,
			},
		}).fire();
	}, [createAnalyticsEvent]);

	return (
		<Spotlight
			data-testid="verified-content-spotlight"
			dialogPlacement="bottom right"
			image={AutomationDiscoveryHeader}
			actions={[
				{
					onClick: handleDismiss,
					text: formatMessage(commonI18n.spotlightDismiss),
				},
				{
					onClick: handleProceed,
					text: formatMessage(commonI18n.spotlightCta),
				},
			]}
			heading={formatMessage(verifiedContentI18n.title)}
			targetRadius={2}
			target="verified-content-state"
			key="verified-content-state"
			dialogWidth={275}
			messageType="engagement"
			messageId="verified-content-spotlight"
		>
			{formatMessage(verifiedContentI18n.body)}
		</Spotlight>
	);
};
