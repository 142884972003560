import React from 'react';

import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';

const styling = xcss({
	borderLeft: `2px solid ${token('color.border')}`,
	display: 'inline-block',
	width: '1px',
	height: '16px',
	marginBlock: 'space.0',
	marginRight: 'space.050',
	marginLeft: 'space.100',
	alignSelf: 'center',
});

export const StyledBorder = () => <Box testId="vertical-line" xcss={styling} />;
