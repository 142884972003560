import { defineMessages } from 'react-intl-next';

export const sharedi18n = defineMessages({
	maxLength: {
		id: 'content-state.status.create.status.max.length',
		description: 'Helper message below text field which tells user the max length is 20',
		defaultMessage: 'Max length of 20',
	},
	requiredErrorMessage: {
		id: 'content-state.status.maker.error.message',
		description:
			'Error message that appears below text field if user does not enter anything in the text field and tries to submit',
		defaultMessage: 'Required',
	},
	emptyStatusFlagTitle: {
		id: 'content-state.status.empty.status.flag.title',
		description:
			'Title in warning flag that shows if user tries to create a status with only empty/white space',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "Your status can't be saved",
	},
	emptyStatusFlagDescription: {
		id: 'content-state.status.empty.status.flag.description',
		description:
			'Description in warning flag that shows if user tries to create a status with only empty/white space',
		defaultMessage: 'Statuses must contain symbols or alphanumeric characters.',
	},
	verifiedRestricted: {
		id: 'content-state.verified-restricted',
		description:
			'Message in the tooltip when user hovers over content state when the content state is verified and can only be altered by page owners or space admins.',
		defaultMessage: 'This page is verified. Only the page owner or an admin can change its status.',
	},
	pageStatus: {
		id: 'content-state.page-status',
		description:
			'Message in the tooltip when user hovers over content state that explains the component is the page status.',
		defaultMessage: 'Page status',
	},
});
