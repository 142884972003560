import React, { type FC, useEffect, useState } from 'react';
import { useCoordination } from '@atlassiansox/engagekit-ts';
import { injectIntl } from 'react-intl-next';

import { SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import {
	VERIFIED_CONTENT_PULSE_KEY,
	EventSourceName,
} from '@confluence/automation-discovery/entry-points/constants';
import { useAutomationPermissionService } from '@confluence/automation-menu/entry-points/AutomationPermissionService';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { useSessionData } from '@confluence/session-data';
import { useCoordinationClient } from '@confluence/engagement-provider';
import { VerifiedContentSpotlight } from '@confluence/automation-discovery/entry-points';
import { useSpaceKey } from '@confluence/space-utils';

import { ContentStateRestrictionLevel } from '../ContentStateColor';

import type { RendererContentStateComponentProps } from './types';
import { RendererContentStateComponent } from './RendererContentStateComponent';
import { StyledBorder } from './StyledBorder';

const setUserHasSeenVerifiedContentSpotlight = (): void => {
	localStorage.setItem(keys.AUTOMATION_DISCOVER_VERIFIED_CONTENT, true);
};

const hasUserSeenVerifiedContentSpotlight = (): boolean => {
	return localStorage.getItemAsBoolean(keys.AUTOMATION_DISCOVER_VERIFIED_CONTENT);
};

const RendererContentStateWithSpotlightImpl: FC<RendererContentStateComponentProps> = ({
	contentId,
	contentState,
	lastUpdatedInfo,
	isPagePreview,
	hasSeparator = true,
}) => {
	const [isSpotlightActive, setIsSpotlightActive] = useState(false);
	const coordinationClient = useCoordinationClient();
	const [pulseStarted, stopPulse] = useCoordination(coordinationClient, VERIFIED_CONTENT_PULSE_KEY);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const spaceKey = useSpaceKey();
	const { edition } = useSessionData();

	const isPremium = ConfluenceEdition.PREMIUM === edition;
	const { canManageAutomations } = useAutomationPermissionService(spaceKey || '');

	const handleProceed = () => {
		setIsSpotlightActive(false);
		if (pulseStarted) {
			void stopPulse();
		}
		window.open(
			`/wiki/spaces/${spaceKey}/settings/automation#/rule/template?templateId=confluence_notify_template_7`,
			'_blank',
		);
		setUserHasSeenVerifiedContentSpotlight();

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'automationDiscoveryVerifiedContentProceed',
				source: EventSourceName.VERIFED_CONTENT,
				attributes: {
					spaceKey,
				},
			},
		}).fire();
	};

	const handleDismiss = () => {
		setIsSpotlightActive(false);
		if (pulseStarted) {
			void stopPulse();
		}
		setUserHasSeenVerifiedContentSpotlight();

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'automationDiscoveryVerifiedContentDismiss',
				source: EventSourceName.VERIFED_CONTENT,
				attributes: {
					spaceKey,
				},
			},
		}).fire();
	};

	const showSpotlight = () => {
		setIsSpotlightActive(true);
	};

	const isVerifiedContentState =
		contentState?.restrictionLevel ===
			ContentStateRestrictionLevel[ContentStateRestrictionLevel.PAGE_OWNER] &&
		contentState.name === 'Verified';

	useEffect(() => {
		if (
			isPremium &&
			isVerifiedContentState &&
			!hasUserSeenVerifiedContentSpotlight() &&
			canManageAutomations
		) {
			setTimeout(() => {
				showSpotlight();
			}, 1);
		}
	}, [isPremium, isVerifiedContentState, canManageAutomations]);

	return (
		<>
			<SpotlightTarget name="verified-content-state">
				<RendererContentStateComponent
					contentId={contentId}
					contentState={contentState}
					lastUpdatedInfo={lastUpdatedInfo}
					isPagePreview={isPagePreview}
					hasSeparator={hasSeparator}
				/>
			</SpotlightTarget>
			<SpotlightTransition>
				{isSpotlightActive && (
					<VerifiedContentSpotlight handleDismiss={handleDismiss} handleProceed={handleProceed} />
				)}
			</SpotlightTransition>
			{/* Move the following border back to RendererContentStateComponent when removing spotlight */}
			{!isPagePreview && hasSeparator ? <StyledBorder /> : null}
		</>
	);
};

export const RendererContentStateWithSpotlight = injectIntl(RendererContentStateWithSpotlightImpl);
