/* eslint-disable check-file/filename-blocklist */
import { PRIMARY_COLORS, SECONDARY_COLORS, HOVER_COLORS } from '../colors';
import { hexToToken } from '../helpers';

import type { ContentState } from './types';

const MAX_ALLOWED_CUSTOM_STATES = 3;

export function determineSecondaryColor(primaryColor?: string | null) {
	const tokenPrimaryColor = hexToToken(primaryColor ?? PRIMARY_COLORS.BLUE);
	switch (tokenPrimaryColor) {
		case PRIMARY_COLORS.BLUE:
			return SECONDARY_COLORS.BLUE;
		case PRIMARY_COLORS.GREEN:
			return SECONDARY_COLORS.GREEN;
		case PRIMARY_COLORS.YELLOW:
			return SECONDARY_COLORS.YELLOW;
		case PRIMARY_COLORS.RED:
			return SECONDARY_COLORS.RED;
		case PRIMARY_COLORS.PURPLE:
			return SECONDARY_COLORS.PURPLE;
		default:
			return SECONDARY_COLORS.BLUE;
	}
}

export function determineHoverColor(secondaryColor: string | null) {
	const tokenSecondaryColor = hexToToken(secondaryColor ?? SECONDARY_COLORS.BLUE);
	switch (tokenSecondaryColor) {
		case SECONDARY_COLORS.BLUE:
			return HOVER_COLORS.BLUE;
		case SECONDARY_COLORS.GREEN:
			return HOVER_COLORS.GREEN;
		case SECONDARY_COLORS.YELLOW:
			return HOVER_COLORS.YELLOW;
		case SECONDARY_COLORS.RED:
			return HOVER_COLORS.RED;
		case SECONDARY_COLORS.PURPLE:
			return HOVER_COLORS.PURPLE;
		default:
			return HOVER_COLORS.BLUE;
	}
}

export function isDuplicate(oldContentState: ContentState | null, newContentState: ContentState) {
	if (!oldContentState) return false; // can't be duplicate if we don't even have a content state originally
	return (
		oldContentState.name === newContentState.name && oldContentState.color === newContentState.color
	);
}

export function findDuplicateStateIdAndIndex(
	customStatesArray: (ContentState | null)[],
	newCustomState: ContentState,
) {
	for (let i = 0; i < customStatesArray.length; ++i) {
		if (isDuplicate(customStatesArray[i], newCustomState)) {
			return { index: i, id: customStatesArray[i]?.id };
		}
	}
	// if we don't find a match just return null
	return null;
}

export function createNewCustomStatesArrayWhenNewCustomAdded(
	oldCustomStatesArray: (ContentState | null)[],
	newCustomState: ContentState,
) {
	/* Need to check if the state user entered matches an existing custom one in the array.
  If it's a duplicate, it means the state the user just typed in is one that already exists. We must find the index
  and id in the old array and handle it like if an existing custom state was selected
   */
	const idAndIndex = findDuplicateStateIdAndIndex(oldCustomStatesArray, newCustomState);
	if (idAndIndex) {
		// user typed in an existing custom state
		return createNewCustomStatesArrayWhenExistingCustomSelected(
			oldCustomStatesArray,
			{ ...newCustomState, id: idAndIndex.id },
			idAndIndex.index,
		);
	} else {
		const newArray = [newCustomState, ...oldCustomStatesArray];
		if (newArray.length > MAX_ALLOWED_CUSTOM_STATES) {
			newArray.pop();
		}
		return newArray;
	}
}

export function createNewCustomStatesArrayWhenExistingCustomSelected(
	oldCustomStatesArray: (ContentState | null)[],
	selectedCustomState: ContentState,
	index: number,
) {
	// removed the selected custom state by index and then add it to the top of the new array
	const newArray = [...oldCustomStatesArray];
	newArray.splice(index, 1);
	newArray.unshift(selectedCustomState);
	return newArray;
}
